@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');


body{
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.lineClamp {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .loader {
    width: 150px;
    height: 150px;
    display: inline-block;
    position: relative;
    top: 10%;
    left: 50%;        
    transform: translate(-10%,-50%);
  }
  .loader::after,
  .loader::before {
    content: '';
    width: 150px;
    height: 150px;
    border: 2px solid #ED1C24;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    animation: rotation 2s ease-in-out infinite;
  }
  .loader::after {
    border-color: #004A80;
    animation-delay: 1s;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

@layer utilities {
    .container{
        @apply max-w-[1440px] mx-auto;
        
    }

    .nav-text {
        @apply text-[14px];
    }

    .text-dblue{
        @apply text-[#003c55] font-[700]
        
    }
}